import { FormProvider } from "react-hook-form";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import { ExternalTextLink } from "components/Links";
import { theme } from "core/theme";
import { PARTNER_TERMS_URL } from "core/urls";
import { PRACTITIONER_TYPES_QUERY } from "graphql/accounts";
import useScrollToError from "hooks/form/useScrollToError";
import Asterisk from "tpo/Asterisk";
import Checkbox from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import ControlledRadioGroup from "tpo/ControlledRadioGroup";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import { Error } from "tpo/InputWrapper";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import PractitionerTypeField from "tpo/partnerDashboard/practitioner/Application/ui/PractitionerTypeField";
import ButtonV2 from "v2/Buttons";

import ProfessionalBodiesField from "./ProfessionalBodiesField";
import QualificationProof from "./QualificationProof";
import RequiredFieldsNotice from "./RequiredFieldsNotice";

export default function Common({
  api,
  yourDetailsFieldset,
  includePartnerUpdatesConsentField = true
}) {
  const { data: { practitionerTypes = [] } = {} } = useQuery(PRACTITIONER_TYPES_QUERY);

  useScrollToError(api);

  return (
    <FormProvider {...api.methods}>
      <CustomerPage
        bg="haze"
        jumbotronProps={{
          bg: "partners",
          title: "Practitioner application",
          color: "dark"
        }}
      >
        <Box pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={[20, 20, "5.5vw"]}>
          <Box maxWidth={760} mx="auto">
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
              Become a Practitioner Partner
            </Box>
            <Spacer py={2} />
            <Box
              as="ul"
              fontFamily="gilroyMedium"
              fontSize={16}
              listStyle="disc"
              style={{
                listStylePosition: "inside"
              }}
            >
              The Practitioner level of access is for qualified health and wellness professionals.
              <Spacer py={2} />
              <Box as="li" fontFamily="inherit">
                Full access to our exclusive suite of 200+ tests at trade prices.
              </Box>
              <Box as="li" fontFamily="inherit">
                Order & manage tests on behalf of your clients.
              </Box>
              <Box as="li" fontFamily="inherit">
                Review and release test results via the Omnos patient platform
              </Box>
            </Box>
            <Spacer py={20} />
            <Stack
              as="form"
              gap={[20, 20, 40]}
              onSubmit={e => {
                api.methods.clearErrors();
                api.methods.handleSubmit(api.onSubmit)(e);
              }}
            >
              {yourDetailsFieldset}
              <Stack bg="white" pt={40} pb={80} px={[20, 20, 80]} gap={40} borderRadius={5}>
                {yourDetailsFieldset ? null : <RequiredFieldsNotice />}
                <FormControl
                  as="fieldset"
                  labelProps={{
                    fontFamily: "gilroyBold",
                    fontSize: [28],
                    lineHeight: "130%"
                  }}
                  label={"Organisation / Business"}
                  fields={
                    <>
                      <ControlledFormField
                        name="businessName"
                        label="Your organisation or business name"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="phoneNumber"
                        label="Contact number"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="jobRole"
                        label="Job title / Role"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="businessLocationCity"
                        label="Location City"
                        Component={FloatingLabelInput}
                      />
                    </>
                  }
                />
                <FormControl
                  as="fieldset"
                  labelProps={{
                    fontFamily: "gilroyBold",
                    fontSize: [16, 16, 18]
                  }}
                  label={
                    <Box fontFamily="inherit" fontSize="inherit">
                      Social media?
                      <Asterisk />
                    </Box>
                  }
                  helpText="Please enter at least one of your social media links"
                  fields={
                    <>
                      <ControlledFormField
                        name="instagram"
                        label="Instagram"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="linkedin"
                        label="Linkedin"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="youtube"
                        label="Youtube"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="websiteBlog"
                        label="Website"
                        Component={FloatingLabelInput}
                      />
                    </>
                  }
                />
              </Stack>
              <Stack bg="white" pt={40} pb={80} px={[20, 20, 80]} gap={40} borderRadius={5}>
                <FormControl
                  label={
                    <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="26px">
                      What role describes you best?
                    </Box>
                  }
                  spaceAboveHelpText={2}
                  helpText={
                    <Box fontFamily="gilroyMedium" fontSize={16}>
                      Select your primary professional focus
                    </Box>
                  }
                  spaceAboveChildren={[20, 20, 40]}
                >
                  <Stack alignItems="flex-start" gap={[20, 20, 40]}>
                    <PractitionerTypeField practitionerTypes={practitionerTypes} />
                  </Stack>
                </FormControl>
                <QualificationProof practitionerTypes={practitionerTypes} />
              </Stack>
              <Stack bg="white" pt={40} pb={80} px={[20, 20, 80]} gap={40} borderRadius={5}>
                <FormControl
                  label={
                    <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="26px">
                      Professional Bodies
                    </Box>
                  }
                  spaceAboveHelpText={2}
                  helpText={
                    <Box fontFamily="gilroyMedium" fontSize={16}>
                      Please give details of any professional bodies you are registered with
                    </Box>
                  }
                  spaceAboveChildren={[20, 20, 40]}
                >
                  <ProfessionalBodiesField />
                </FormControl>
              </Stack>
              <Stack bg="white" pt={40} pb={80} px={[20, 20, 80]} gap={40} borderRadius={5}>
                <FormControl
                  as="fieldset"
                  labelProps={{
                    fontFamily: "gilroyBold",
                    fontSize: [16, 16, 18]
                  }}
                  label={
                    <Box fontFamily="inherit" fontSize="inherit">
                      How did you hear about Omnos and the Omnos partner programme?
                      <Asterisk />
                    </Box>
                  }
                  fields={
                    <>
                      <ControlledRadioGroup
                        Container={Stack}
                        containerProps={{
                          gap: 20
                        }}
                        name="sources"
                        values={[
                          {
                            label: "Instagram",
                            value: "Instagram"
                          },
                          {
                            label: "Direct email",
                            value: "Direct email"
                          },
                          {
                            label: "Influencer",
                            value: "Influencer"
                          },
                          {
                            label: "Magazine",
                            value: "Magazine"
                          },
                          {
                            label: "Google Search",
                            value: "Google Search"
                          },
                          {
                            label: "Event",
                            value: "Event"
                          },
                          {
                            label: "Staff",
                            value: "Staff"
                          },
                          {
                            label: "Friends/Family",
                            value: "Friends / Family"
                          },
                          {
                            label: "Other",
                            value: "Other"
                          }
                        ]}
                      />
                      <ControlledFormField
                        name="sourcesOther"
                        label="Please give additional details"
                        Component={FloatingLabelInput}
                      />
                    </>
                  }
                />
                <FormControl
                  fields={
                    <>
                      {includePartnerUpdatesConsentField && (
                        <ControlledFormField
                          label="By submitting this form, you indicate your consent to receiving our latest health news, exclusive promotions and product updates. Tick this box if you would not like to receive this information"
                          name="partnerUpdatesConsent"
                          Component={Checkbox}
                        />
                      )}
                      <ControlledFormField
                        label={
                          <>
                            I have read and agree to the{" "}
                            <ExternalTextLink href={PARTNER_TERMS_URL}>
                              terms and conditions
                              <Asterisk />
                            </ExternalTextLink>
                          </>
                        }
                        name="acceptTerms"
                        Component={Checkbox}
                      />
                    </>
                  }
                />
                <ButtonV2
                  color="dark"
                  rightIcon={<ChevronComponent />}
                  mr="auto"
                  size={["sm", "sm", "md"]}
                >
                  Submit
                </ButtonV2>
                {!!api.nonFieldError && <Error error={api.nonFieldError} />}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </CustomerPage>
    </FormProvider>
  );
}
