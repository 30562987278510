import Box from "components/Box";
import getResponsiveProps from "utils/getResponsiveProps";

function Badge({ children, size: sizeProp, withBorder, color, ...props }) {
  // TODO - look at supporting similar props to the Button / RoundedTab
  // size, color, withBorder

  const sizes = {
    xs: {
      fontSize: 9,
      height: 24,
      px: 20,
      py: "12px"
    },
    sm: {
      fontSize: 9,
      height: 28,
      px: 16,
      py: "8px"
    },
    md: {
      fontSize: 12,
      height: 28,
      px: 20,
      py: 12
    },
    lg: {
      fontSize: 12,
      height: 40,
      px: 18,
      py: 16
    },
    xl: {
      fontSize: 12,
      height: 48,
      px: 20,
      py: 16
    }
  };

  let sizeProps = getResponsiveProps(sizes, sizeProp);

  if (withBorder) {
    props = {
      ...props,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: color
    };
  }

  return (
    <Box {...sizeProps} color={color} {...props}>
      {children}
    </Box>
  );
}

Badge.defaultProps = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "uppercase",
  borderRadius: 100,
  fontFamily: "gilroyBold",
  letterSpacing: "1px",
  "data-component-name": "Badge",
  size: "sm",
  whiteSpace: "nowrap"
};

export default Badge;
