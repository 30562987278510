import { useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import OnboardingStatusChecker from "components/notifications/ui/OnboardingStatusChecker";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

import StripeCalloutBanner from "./StripeCalloutBanner";

export default function StripeConnectSetupPanel() {
  const { organisation } = useOrganisationContext();
  const [checkingStatus, setCheckingStatus] = useState(false);

  return (
    <Box>
      <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
        Link your bank account
      </Box>
      <Spacer py={20} />
      <Box fontFamily="gilroyMedium" fontSize={16} mb={5}>
        Before you can add clients or receive commissions you’ll need to link a bank account for
        payment. Once completed you will automatically receive commission payouts to your bank
        account on a monthly basis as well as be able to order stock tests.
      </Box>
      <Spacer py={20} />
      <Box fontFamily="gilroyBold" fontSize={18}>
        Payment provider
      </Box>
      <Spacer py={15} />
      <StripeCalloutBanner />
      <Spacer py={5} />
      <Center>
        <ExternalTextLink
          href={organisation?.onboardingUrl}
          target="_self"
          rel="noopener noreferrer"
        >
          <ButtonV2
            color="green"
            rightIcon={<ChevronComponent opacity={checkingStatus ? 0 : 1} />}
            type="button"
            size={["sm", "sm", "md"]}
          >
            Complete Set Up
            <OnboardingStatusChecker onChange={active => setCheckingStatus(active)} />
          </ButtonV2>
        </ExternalTextLink>
      </Center>
    </Box>
  );
}
