import Box from "components/Box";
import { InternalTextLink } from "components/Links";
import { PRACTITIONER } from "core/constants";
import { LOGIN_URL } from "core/urls";
import { CREATE_PARTNER_USER_PROFILE_MUTATION } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Asterisk from "tpo/Asterisk";
import ControlledFormField from "tpo/ControlledFormField";
import ControlledRadioGroup from "tpo/ControlledRadioGroup";
import DateInput from "tpo/DateInput";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import FloatingLabelPasswordInput from "tpo/PasswordInput";
import Stack from "tpo/Stack";

import Common from "./CommonForm";
import RequiredFieldsNotice from "./RequiredFieldsNotice";

export default function LoggedOutForm({ setFormSubmitted }) {
  const api = useDjangoGraphqlForm({
    transformer: input => ({
      ...input,
      professionalBodies: input.professionalBodies.join(","),
      documents: JSON.stringify(
        input.documents.reduce(
          (acc, doc) => ({
            ...acc,
            [doc.type]: {
              key: doc.key,
              name: doc.name
            }
          }),
          {}
        )
      )
    }),
    mutation: CREATE_PARTNER_USER_PROFILE_MUTATION,
    mutationName: "createPartnerUserProfileMutation",
    defaultValues: {
      tier: PRACTITIONER,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      dateOfBirth: "",
      gender: "",

      practitionerType: "",
      professionalBodies: [],
      documents: [],

      businessName: "",
      phoneNumber: "",
      jobRole: "",
      businessLocationCity: "",
      sources: "",
      sourcesOther: "",
      instagram: "",
      linkedin: "",
      youtube: "",
      websiteBlog: "",
      partnerUpdatesConsent: false,
      acceptTerms: false
    },
    handleSuccess: () => {
      setFormSubmitted(true);
    }
  });

  return (
    <Common
      api={api}
      yourDetailsFieldset={
        <>
          <Stack bg="white" pt={40} pb={80} px={[20, 20, 80]} gap={40} borderRadius={5}>
            <RequiredFieldsNotice />
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
              Your details
              <Asterisk />
            </Box>
            <FormControl
              as="fieldset"
              labelProps={{
                fontFamily: "gilroyBold",
                fontSize: [28],
                lineHeight: "130%"
              }}
              fields={
                <>
                  <ControlledFormField
                    name="firstName"
                    label="First name"
                    Component={FloatingLabelInput}
                  />
                  <ControlledFormField
                    name="lastName"
                    label="Last name"
                    Component={FloatingLabelInput}
                  />
                  <ControlledFormField
                    name="email"
                    label="Email address"
                    Component={FloatingLabelInput}
                  />
                </>
              }
            />
            <FormControl
              as="fieldset"
              label={
                <Box fontFamily="gilroyBold" fontSize={[18]}>
                  Password
                  <Asterisk />
                </Box>
              }
              fields={
                <>
                  <ControlledFormField
                    name="password"
                    label="Password"
                    Component={FloatingLabelPasswordInput}
                  />
                  <ControlledFormField
                    name="confirmPassword"
                    label="Confirm Password"
                    Component={FloatingLabelPasswordInput}
                  />
                </>
              }
            />
            <Box fontFamily="gilroyMedium" fontSize={16}>
              Already a user? Click <InternalTextLink href={LOGIN_URL}>here</InternalTextLink> login
            </Box>
            <ControlledFormField
              name="dateOfBirth"
              Component={DateInput}
              getDay={dateStr => (dateStr ? dateStr.split("-")[2] : "")}
              getMonth={dateStr => (dateStr ? dateStr.split("-")[1] : "")}
              getYear={dateStr => (dateStr ? dateStr.split("-")[0] : "")}
              constructDate={({ day, month, year }) => {
                return `${year}-${month}-${day}`;
              }}
              label="Date of birth"
              labelProps={{
                fontFamily: "gilroyBold",
                fontSize: 18,
                mb: 20
              }}
            />
            <ControlledRadioGroup
              Container={Stack}
              containerProps={{
                gap: 20
              }}
              name="gender"
              label="Gender"
              labelProps={{
                fontFamily: "gilroyBold",
                fontSize: 18,
                mb: 20
              }}
              values={[
                {
                  label: "Male",
                  value: "Male"
                },
                {
                  label: "Female",
                  value: "Female"
                }
              ]}
            />
          </Stack>
        </>
      }
    />
  );
}
