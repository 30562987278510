import { getAPIBase } from "./config";
import { PEOPLE_TO_SEE, THINGS_TO_AVOID, THINGS_TO_DO, THINGS_TO_TAKE } from "./constants";

// External URLs
export const MARKETING_URL = "https://www.omnos.me/";
export const APP_URL = "http://app.omnos.me/";
export const HOW_IT_WORKS_URL = "https://www.omnos.me/how-it-works";
export const OUR_MISSION_URL = "https://www.omnos.me/about#mission";
export const THE_SCIENCE_URL = "https://www.omnos.me/about#science";
export const MEET_THE_TEAM_URL = "https://www.omnos.me/about#team";
export const BLOG_URL = "https://www.omnos.me/articles";
export const CONTACT_US_URL = "mailto:support@omnos.me";
export const HELP_CENTER_URL = "https://faq.omnos.me/knowledge";
export const FOR_RETAILERS_URL = "https://www.omnos.me/partners#business";
export const PARTNERS_EXTERNAL_URL = "https://www.omnos.me/partners";

// Slugs
export const OVERVIEW_SLUG = "overview";
export const RECOMMENDATIONS_SLUG = "recommendations";
export const RESULTS_SLUG = "results";
export const SCORES_SLUG = "scores";
export const CORE_SECTOR_SUBPATHS = [
  OVERVIEW_SLUG,
  SCORES_SLUG,
  RECOMMENDATIONS_SLUG,
  RESULTS_SLUG
];

// Core
export const HOME_URL = "/";
export const DASHBOARD_HOME_URL = "/dashboard";
export const FOODS_URL = "/food-list";
export const SETTINGS_URL = "/settings";
export const UPLOAD_TEST = "/upload-test";
export const EXPERTS_URL = "/experts";
export const EXPERTS_DETAIL_URL = "/experts/:id";

export const getExpertsDetailUrl = expertId => EXPERTS_DETAIL_URL.replace(":id", expertId);

// Results
export const RESULTS_URL_BASE = "/results";
export const RESULTS_PATTERN_URL = `${RESULTS_URL_BASE}/:sectorSlug/:slug`;
export function getResultUrlFromSlugs(sectorSlug, slug) {
  return RESULTS_PATTERN_URL.replace(":sectorSlug", sectorSlug).replace(":slug", slug);
}
// Non-Sector Based Results
export const NSB_RESULTS_PATTERN_URL = `${RESULTS_URL_BASE}/:testCode`;
export function getResultUrlFromTest(testInput) {
  return NSB_RESULTS_PATTERN_URL.replace(":testCode", testInput.id);
}

// Recommendations slugs
export const THINGS_TO_DO_SLUG = "things-to-do";
export const THINGS_TO_AVOID_SLUG = "things-to-avoid";
export const THINGS_TO_TAKE_SLUG = "supplements";
export const PEOPLE_TO_SEE_SLUG = "people-to-see";

// Things to do
export const THINGS_TO_DO_URL_BASE = "/things-to-do";
export const THING_TO_DO_PATTERN_URL = "/things-to-do/:slug";
export function getThingToDoUrl(slug) {
  return THING_TO_DO_PATTERN_URL.replace(":slug", slug);
}

// Things to avoid
export const THINGS_TO_AVOID_URL_BASE = "/things-to-avoid";
export const THING_TO_AVOID_PATTERN_URL = "/things-to-avoid/:slug";
export function getThingToAvoidUrl(slug) {
  return THING_TO_AVOID_PATTERN_URL.replace(":slug", slug);
}

// Things to take
export const SUPPLEMENTS_URL = "/supplements";
export const SUPPLEMENT_PATTERN_URL = "/supplements/:slug";
export function getSupplementUrl(slug) {
  return SUPPLEMENT_PATTERN_URL.replace(":slug", slug);
}

// People to see
export const PEOPLE_TO_SEE_URL_BASE = "/people-to-see";
export const PERSON_TO_SEE_PATTERN_URL = "/people-to-see/:slug";
export function getPersonToSeeUrl(slug) {
  return PERSON_TO_SEE_PATTERN_URL.replace(":slug", slug);
}

export const RECOMMENDATION_PATTERN_URL = "/:recommendationTypeSlug/:slug";
export function getRecommendationUrl(recommendation) {
  const recommendationTypeSlug = getRecommendationTypeSlug(recommendation);
  return RECOMMENDATION_PATTERN_URL.replace(
    ":recommendationTypeSlug",
    recommendationTypeSlug
  ).replace(":slug", recommendation.slug);
}

export function getRecommendationTypeSlug(recommendation) {
  if (recommendation.recommendationType === THINGS_TO_DO) return THINGS_TO_DO_SLUG;
  if (recommendation.recommendationType === THINGS_TO_AVOID) return THINGS_TO_AVOID_SLUG;
  if (recommendation.recommendationType === THINGS_TO_TAKE) return THINGS_TO_TAKE_SLUG;
  if (recommendation.recommendationType === PEOPLE_TO_SEE) return PEOPLE_TO_SEE_SLUG;
  console.error("Unrecognised recommendationType : ", recommendation.recommendationType);
}

// Questionnaires
export const QUESTIONNAIRES_URL_BASE = "/questionnaires";
export const QUESTIONNAIRE_INTRO_PATTERN_URL = `${QUESTIONNAIRES_URL_BASE}/:slug/intro`;
export function getQuestionnaireIntroUrl(slug) {
  return QUESTIONNAIRE_INTRO_PATTERN_URL.replace(":slug", slug);
}
export const QUESTIONNAIRE_SUBMIT_PATTERN_URL = `${QUESTIONNAIRES_URL_BASE}/:slug/submit`;
export function getQuestionnaireSubmitUrl(slug) {
  return QUESTIONNAIRE_SUBMIT_PATTERN_URL.replace(":slug", slug);
}

export const TEST_PRODUCT_QUESTIONNAIRE_INTRO_URL = `${QUESTIONNAIRES_URL_BASE}/:testProductSlug/:questionnaireSlug/intro`;
export function getTestProductQuestionnaireIntroUrl(testProductSlug, questionnaireSlug) {
  return TEST_PRODUCT_QUESTIONNAIRE_INTRO_URL.replace(":testProductSlug", testProductSlug).replace(
    ":questionnaireSlug",
    questionnaireSlug
  );
}

export const TEST_PRODUCT_QUESTIONNAIRE_STEP_PATTERN_URL = `${QUESTIONNAIRES_URL_BASE}/:testProductSlug/:questionnaireSlug/questions`;
export function getTestProductQuestionnaireStepUrl(testProductSlug, questionnaireSlug) {
  return TEST_PRODUCT_QUESTIONNAIRE_STEP_PATTERN_URL.replace(
    ":testProductSlug",
    testProductSlug
  ).replace(":questionnaireSlug", questionnaireSlug);
}

export const TEST_PRODUCT_QUESTIONNAIRE_QUESTIONS_PATTERN_URL = `${QUESTIONNAIRES_URL_BASE}/:testProductSlug/:questionnaireSlug/questions`;
export function getTestProductQuestionnaireQuestionsUrl(testProductSlug, questionnaireSlug) {
  return TEST_PRODUCT_QUESTIONNAIRE_QUESTIONS_PATTERN_URL.replace(
    ":testProductSlug",
    testProductSlug
  ).replace(":questionnaireSlug", questionnaireSlug);
}

export const TEST_PRODUCT_QUESTIONNAIRE_OUTRO_URL = `${QUESTIONNAIRES_URL_BASE}/:testProductSlug/:questionnaireSlug/outro`;
export function getTestProductQuestionnaireOutroUrl(testProductSlug, questionnaireSlug) {
  return TEST_PRODUCT_QUESTIONNAIRE_OUTRO_URL.replace(":testProductSlug", testProductSlug).replace(
    ":questionnaireSlug",
    questionnaireSlug
  );
}

export const QUESTIONNAIRE_STEP_PATTERN_URL = `${QUESTIONNAIRES_URL_BASE}/:slug/:step`;
export function getQuestionnaireStepUrl(slug, step) {
  return QUESTIONNAIRE_STEP_PATTERN_URL.replace(":slug", slug).replace(":step", step);
}

export const QUESTIONNAIRE_OUTRO_PATTERN_URL = `${QUESTIONNAIRES_URL_BASE}/:slug/outro`;
export function getQuestionnaireOutroUrl(slug) {
  return QUESTIONNAIRE_OUTRO_PATTERN_URL.replace(":slug", slug);
}

// Orders
export const ORDERS_URL = "/orders";
export const ORDER_PATTERN_URL = "/orders/:orderId";
export function getOrderUrl(orderId) {
  return ORDER_PATTERN_URL.replace(":orderId", orderId);
}
export const ORDER_PROCESSING_PATTERN_URL = "/orders/:orderId/processing";
export function getOrderProcessingUrl(orderId) {
  return ORDER_PROCESSING_PATTERN_URL.replace(":orderId", orderId);
}

// Sectors
export const SECTOR_URL_BASE = "/sectors";
export const SECTOR_PATTERN_URL = `${SECTOR_URL_BASE}/:sectorSlug/:slug`;
export function getSectorUrl(sectorSlug, slug) {
  return SECTOR_PATTERN_URL.replace(":sectorSlug", sectorSlug).replace(":slug", slug);
}
export function getSectorUrlPattern(slug) {
  return `${SECTOR_URL_BASE}/:slug/${slug}`;
}

// Tests

export const SHOP_URL = "/shop";
export const SHOP_TESTS_URL = `${SHOP_URL}/tests`;
export const SHOP_TEST_PRODUCT_PATTERN_URL = "/shop/tests/:slug";
export function getShopTestProductUrl(slug) {
  return SHOP_TEST_PRODUCT_PATTERN_URL.replace(":slug", slug);
}
export const SHOP_SUPPLEMENTS_URL = `${SHOP_URL}/supplements`;
export const SHOP_SUPPLEMENT_PATTERN_URL = "/shop/supplements/:slug";
export function getShopSupplementUrl(slug) {
  return SHOP_SUPPLEMENT_PATTERN_URL.replace(":slug", slug);
}

export const ALL_TESTS_SLUG = "all-tests";
export const TESTS_URL = `/${ALL_TESTS_SLUG}`;
export const TEST_FAMILY_PATTERN_URL = `/${ALL_TESTS_SLUG}/:familySlug`;
export function getTestFamilyUrl(familySlug) {
  return TEST_FAMILY_PATTERN_URL.replace(":familySlug", familySlug);
}
export const TEST_PATTERN_URL = `/${ALL_TESTS_SLUG}/:familySlug/:slug`;
export function getTestUrl(slug, familySlug) {
  return TEST_PATTERN_URL.replace(":slug", slug).replace(":familySlug", familySlug);
}

// Old Tests Redirect
export const CATCH_SHOP_URL = "/shop";
export const OLD_TESTS_URL = "/tests";
export const OLD_TEST_PATTERN_URL = "/tests/:slug";

// Kit Registration
export const REGISTER_KIT_URL = "/register";
export const REGISTER_KIT_SIGNUP_URL = "/register/signup";
export const REGISTER_KIT_QUESTIONS_URL = "/register/details";
export const REGISTER_KIT_COMPLETE_URL = "/register/complete";

// Kit Registration
export const EXTERNAL_KIT_REGISTRATION_URL = "/external-kit-registration";
export const EXTERNAL_KIT_REGISTRATION_COMPLETE_URL = `${EXTERNAL_KIT_REGISTRATION_URL}/complete`;

// Accounts
export const SIGNUP_URL = "/accounts/signup";
export const SIGNUP_DONE_URL = "/accounts/signup/done";
export const SIGNUP_CONFIRM_PATTERN_URL = "/accounts/signup/:userId/:token";
export const SIGNUP_COMPLETE_URL = "/accounts/signup/complete";

export const LOGIN_URL = "/accounts/login";
export const LOGGED_OUT_URL = "/accounts/logged-out";

export const ACCEPT_ADMIN_INVITE_PATTERN_URL = "/accounts/admin/invite/:invitationId";
export const ACCEPT_PARTNER_INVITE_PATTERN_URL = "/accounts/partner/invite/:invitationId";
export const ACCEPT_PARTNER_LINK_INVITE_PATTERN_URL = "/accounts/partner/link/:partnerLinkCode";
export const ACCEPT_INVITE_SUCCESS_URL = "/accounts/invite/success";
export const ACCEPT_RESULTS_ACCESS_REQUEST_PATTERN_URL =
  "/accounts/partner/results-access-request/:requestId";

export const CHANGE_PASSWORD_URL = "/accounts/change-password";
export const CHANGE_PASSWORD_COMPLETE_URL = "/accounts/change-password/success";

export const REQUEST_PASSWORD_RESET_URL = "/accounts/password-reset";
export const REQUEST_PASSWORD_RESET_DONE = "/accounts/password-reset/email-sent";
export const RESET_PASSWORD_CONFIRM_PATTERN_URL = "/accounts/password-reset/:userId/:token";
export const RESET_PASSWORD_COMPLETE_URL = "/accounts/password-reset/success";

// Profile
export const PLATFORM_PROFILE_WELCOME_URL = "/profile/welcome";
export const PLATFORM_PROFILE_FORM_URL = "/profile";
export const PLATFORM_PROFILE_DIET_PREFERENCES_URL = "/profile/diet-preferences";
export const PLATFORM_PROFILE_GOALS_URL = "/profile/goals";

// Discount code
export const DISCOUNT_CODE_REDIRECT_URL = "/code/:discountCode";

// partner share link
export const PARTNER_SHARE_LINK_URL = "/link/:partnerLinkCode";

// Legal
export const COOKIE_URL = "/cookie-policy";
export const PRIVACY_URL = "/privacy-policy";
export const TERMS_OF_SERVICE_URL = "/terms-of-service";
export const TERMS_OF_USE_URL = "/terms-of-use";
export const PARTNER_TERMS_URL = "/partner-terms-and-conditions";
export const SHIPPING_AND_RETURNS_URL = "/shipping-and-returns-policy";

// Utils
export const ERROR_404_URL = "/404";
export const ERROR_500_URL = "/500";

// Partners
export const PARTNERS_URL = "/partners";
export const PARTNERS_SIGNIN_URL = `${PARTNERS_URL}/signin`;
export const PARTNERS_DASHBOARD_URL = `${PARTNERS_URL}/dashboard`;
export const PARTNERS_PROFILE_URL = `${PARTNERS_DASHBOARD_URL}/profile`;
export const PARTNERS_ORGANISATIONS_URL = `${PARTNERS_DASHBOARD_URL}/business`;
export const PARTNERS_COMMISSIONS_URL = `${PARTNERS_DASHBOARD_URL}/commissions`;
export const PARTNERS_CHECKOUT_URL = `${PARTNERS_DASHBOARD_URL}/checkout`;
export const PARTNERS_AFFILIATE_LINKS_URL = `${PARTNERS_DASHBOARD_URL}/affiliate_links`;
export const PARTNERS_SHOP_URL = `${PARTNERS_DASHBOARD_URL}/shop/:tab`;
export const PARTNERS_PATIENTS_URL = `${PARTNERS_DASHBOARD_URL}/patients/:tab`;
export const PARTNERS_ORDER_URL = `${PARTNERS_DASHBOARD_URL}/order`;
export const PARTNERS_ORDERS_BASE_URL = `${PARTNERS_DASHBOARD_URL}/orders`;
export const PARTNERS_ORDERS_URL = `${PARTNERS_ORDERS_BASE_URL}/direct`;
export const PARTNERS_STOCK_URL = `${PARTNERS_ORDERS_BASE_URL}/stock`;

export function getPartnerDashboardTabUrl(slug) {
  return `${PARTNERS_DASHBOARD_URL}/${slug}`;
}

export function getPartnerShopTabUrl(tab) {
  return PARTNERS_SHOP_URL.replace(":tab", tab);
}

export function getPartnerPatientTabUrl(tab) {
  return PARTNERS_PATIENTS_URL.replace(":tab", tab);
}

export function getPartnerDashboardUrl(tab) {
  return `${PARTNERS_DASHBOARD_URL}/${tab}`;
}

export function getPartnerDashboardOrderUrl(orderId) {
  return `${PARTNERS_ORDER_URL}/${orderId}`;
}

export const PARTNERS_AFFILIATE_URL = `${PARTNERS_URL}/affiliate`;
export const PARTNERS_PRACTITIONER_URL = `${PARTNERS_URL}/practitioner`;

export const PARTNER_TEST_RESULTS_URL_BASE = `${PARTNERS_URL}/test-results`;
export const PARTNER_TEST_RESULTS_URL = `${PARTNER_TEST_RESULTS_URL_BASE}/:clientId/:userTestId`;

export function getPartnerTestResultsUrl(clientId, userTestId) {
  return PARTNER_TEST_RESULTS_URL.replace(":clientId", clientId).replace(":userTestId", userTestId);
}

export const PATIENT_URL = "/patients/:patientId";
export const PATIENT_TEST_RESULT_URL = `${PATIENT_URL}/result/:userResultId`;

export function getPatientTestResultUrl(patientId, userResultId) {
  return PATIENT_TEST_RESULT_URL.replace(":patientId", patientId).replace(
    ":userResultId",
    userResultId
  );
}

export function getPatientFoodListUrl(patientId) {
  return "/patients/:patientId/food_list".replace(":patientId", patientId);
}

export const PARTNER_QUESTIONNAIRE_RESULTS_URL = `${PARTNERS_URL}/questionnaire/:questionnaireId/results/:clientId`;

export function getPartnerQuestionnaireResultsUrl(clientId, questionnaireId) {
  return PARTNER_QUESTIONNAIRE_RESULTS_URL.replace(":questionnaireId", questionnaireId).replace(
    ":clientId",
    clientId
  );
}

const PATIENT_RECOMMENDATION_URL = "/patients/:patientId/recommendations/:recommendationId";

export function getPatientRecommendationUrl(patientId, recommendationId) {
  return PATIENT_RECOMMENDATION_URL.replace(":patientId", patientId).replace(
    ":recommendationId",
    recommendationId
  );
}

export function getPatientSupplementUrlUsingSlug(patientId, supplementSlug) {
  return "/patients/:patientId/supplements/:slug"
    .replace(":patientId", patientId)
    .replace(":slug", supplementSlug);
}

export const PARTNER_RECOMMENDATION_PATTERN_URL = `${PARTNERS_URL}/:userId/recommendations/:recommendationTypeSlug/:recommendationSlug`;

export function getPartnerRecommendationUrl(userId, recommendation) {
  const recommendationTypeSlug = getRecommendationTypeSlug(recommendation);
  return PARTNER_RECOMMENDATION_PATTERN_URL.replace(":userId", userId)
    .replace(":recommendationTypeSlug", recommendationTypeSlug)
    .replace(":recommendationSlug", recommendation.slug);
}

const PATIENT_RECOMMENDATIONS_URL = "/patients/:patientId/recommendations";

export function getPatientRecommendationsUrl(patientId) {
  return PATIENT_RECOMMENDATIONS_URL.replace(":patientId", patientId);
}

// Client Management Platform

export const CLIENT_MANAGEMENT_PLATFORM_URL_BASE = "/patients/:patientId/:tab";
export const CLIENT_MANAGEMENT_USER_SUBSECTOR = `${CLIENT_MANAGEMENT_PLATFORM_URL_BASE}/:subsectorSlug`;
export const CLIENT_MANAGEMENT_USER_TEST_URL = `${CLIENT_MANAGEMENT_PLATFORM_URL_BASE}/:userTestId`;

export function getClientManagementTabUrl(userId, tab = "tests") {
  // i.e. first page when partner clicks on "View results"
  return CLIENT_MANAGEMENT_PLATFORM_URL_BASE.replace(":patientId", userId).replace(":tab", tab);
}

export function getClientManagementUserTestUrl(userId, userTestId) {
  return CLIENT_MANAGEMENT_USER_TEST_URL.replace(":patientId", userId)
    .replace(":tab", "tests")
    .replace(":userTestId", userTestId);
}
export function getClientManagementUserSubsectorUrl(userId, subsectorSlug) {
  return CLIENT_MANAGEMENT_USER_SUBSECTOR.replace(":patientId", userId)
    .replace(":tab", "wellness")
    .replace(":subsectorSlug", subsectorSlug);
}

// Hijack

export const RELEASE_IMPERSONATION_URL = `${getAPIBase()}/hijack/release-hijack/?next=/omnos-admin/accounts/user/`;

// TPO

export const TPO_TEST_URL = "/tpo";

export const THEME_URL_BASE = "/theme";
export const THEME_URL = `${THEME_URL_BASE}/:themeSlug`;
export const THEME_SECTOR_URL = `${THEME_URL}/:sectorSlug`;
export const THEME_SUBSECTOR_URL = `${THEME_URL}/:subsectorSlug`;

export function getThemeUrl(themeSlug) {
  return THEME_URL.replace(":themeSlug", themeSlug);
}

export function getTPOSectorUrl(themeSlug, sectorSlug) {
  return THEME_SECTOR_URL.replace(":themeSlug", themeSlug).replace(":sectorSlug", sectorSlug);
}

export function getTPOSubsectorUrl(themeSlug, subsectorSlug) {
  return THEME_SUBSECTOR_URL.replace(":themeSlug", themeSlug).replace(
    ":subsectorSlug",
    subsectorSlug
  );
}

export const PARTNER_THEME_SUBSECTOR_URL = `${PARTNERS_URL}/:clientId${THEME_URL}/:subsectorSlug`;
export function getPartnerTPOSubsectorUrl(themeSlug, subsectorSlug, clientId) {
  return PARTNER_THEME_SUBSECTOR_URL.replace(":clientId", clientId)
    .replace(":themeSlug", themeSlug)
    .replace(":subsectorSlug", subsectorSlug);
}

export const TEST_RESULTS_URL_BASE = "/test-results";

export const TEST_RESULTS_URL = `${TEST_RESULTS_URL_BASE}/:userTestId`;

export function getTestResultsUrl(userTestId) {
  return TEST_RESULTS_URL.replace(":userTestId", userTestId);
}

export const TEST_RESULT_URL_BASE = "/test-result";

export const TEST_RESULT_URL = `${TEST_RESULT_URL_BASE}/:userResultId`;

// the term "biomarker" is for the user
// internally we use the term "datapoint"
export const TEST_DATAPOINT_URL_BASE = "/test-biomarker";
export const TEST_DATAPOINT_URL = `${TEST_DATAPOINT_URL_BASE}/:userDatapointId`;

export function getTestDatapointUrl(userDatapointId) {
  return TEST_DATAPOINT_URL.replace(":userDatapointId", userDatapointId);
}

export const PATIENT_TEST_BIOMARKER_URL = "/patients/:patientId/biomarker/:userDatapointId";

export function getPatientTestBiomarkerUrl(patientId, userDatapointId) {
  return PATIENT_TEST_BIOMARKER_URL.replace(":patientId", patientId).replace(
    ":userDatapointId",
    userDatapointId
  );
}

export function getTestResultUrl(userResultId) {
  return TEST_RESULT_URL.replace(":userResultId", userResultId);
}

export const RECOMMENDATIONS_URL = "/recommendations";

export const RECOMMENDATION_URL = `${RECOMMENDATIONS_URL}/:recommendationId`;

export function getTPORecommendationUrl(recommendationId) {
  return RECOMMENDATION_URL.replace(":recommendationId", recommendationId);
}

export const SUPPLEMENT_URL = `${SUPPLEMENTS_URL}/:slug`;

export function getTPOSupplementUrl(slug) {
  return SUPPLEMENT_URL.replace(":slug", slug);
}

export const FOOD_URL = `${FOODS_URL}/:foodId`;
export function getTPOFoodUrl(foodId) {
  // return FOOD_URL.replace(":foodId", foodId);
  // is there a particular page for a food item?
  return FOODS_URL;
}
