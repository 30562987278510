import React from "react";

import useDocTitle from "../hooks/use-doc-title";
import { CustomerAccountPage } from "./CustomerPage";
import Text from "./Text";

function RequestPasswordResetDonePage() {
  useDocTitle("Request password reset done");
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Password reset link sent"
      }}
    >
      <Text>A password reset link has been sent to the email address you provided.</Text>
      <Text pt={40}>Please check your inbox.</Text>
    </CustomerAccountPage>
  );
}

export default RequestPasswordResetDonePage;
