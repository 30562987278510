import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import DataLoader from "components/DataLoader";
import Error404Page from "components/Error404Page";
import Page from "components/Page";
import PartnersMarketingRedirect from "components/PartnersMarketingRedirect";
import PartnersPage from "components/PartnersPage";
import ParamsContext from "contexts/ParamsContext";
import { PRACTITIONER } from "core/constants";
import {
  PARTNERS_DASHBOARD_URL,
  getPatientFoodListUrl,
  getPatientRecommendationUrl,
  getPatientRecommendationsUrl,
  getPatientSupplementUrlUsingSlug,
  getPatientTestBiomarkerUrl,
  getPatientTestResultUrl
} from "core/urls";
import { PARTNER_USER_PROFILE_QUERY } from "graphql/accounts";

import DashboardSwitcher from "./DashboardSwitcher";
import LoggedOutRoute from "./LoggedOutRoute";
import Params from "./Params";
import PartnerRoute from "./PartnerRoute";
import SignIn from "./SignIn";
import PartnerDashboard from "./partnerDashboard";
import AffiliateSignupRedirect from "./partnerDashboard/affiliate/SignupRedirect";

function Partners() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <PartnersMarketingRedirect />
      </Route>
      <Route from={`${match.path}/affiliate`} exact>
        <AffiliateSignupRedirect />
      </Route>
      <Route path={`${match.path}/practitioner`} exact>
        <PartnersPage tier={PRACTITIONER} />
      </Route>
      <LoggedOutRoute path={`${match.path}/signin`} exact partners={true}>
        <SignIn bg="partners" color="dark" title="Sign In" />
      </LoggedOutRoute>
      <PartnerRoute
        renderLoadingTemplate={loadingContent => (
          <Page addNavBarHeight={false} isPartnerPage>
            <DashboardSwitcher bg="partners" isPartnerPage />
            {loadingContent}
          </Page>
        )}
        path={`${match.path}/dashboard`}
      >
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <DataLoader
            query={PARTNER_USER_PROFILE_QUERY}
            render={({ partnerUserProfile }) => (
              <PartnerDashboard partnerUserProfile={partnerUserProfile} />
            )}
          />
        </Page>
      </PartnerRoute>
      <Route path={`${match.path}/:tab`} exact>
        <Redirect to={PARTNERS_DASHBOARD_URL} />
      </Route>
      <Route path={`${match.path}/test-result/:clientId/:userResultId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, userResultId }) => (
              <Redirect to={getPatientTestResultUrl(clientId, userResultId)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/test-biomarker/:clientId/:userDatapointId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, userDatapointId }) => (
              <Redirect to={getPatientTestBiomarkerUrl(clientId, userDatapointId)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/:clientId/recommendations`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId }) => <Redirect to={getPatientRecommendationsUrl(clientId)} />}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/:clientId/recommendations/:recommendationId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, recommendationId }) => (
              <Redirect to={getPatientRecommendationUrl(clientId, recommendationId)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/supplements/:clientId/:slug`}>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, slug }) => (
              <Redirect to={getPatientSupplementUrlUsingSlug(clientId, slug)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/food-list/:clientId/`}>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId }) => <Redirect to={getPatientFoodListUrl(clientId)} />}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Error404Page />
    </Switch>
  );
}

export default Partners;
