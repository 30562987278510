import { useHistory } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import OrganisationBadge from "components/organisations/shared/ui/OrganisationBadge";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { AFFILIATE } from "core/constants";
import { theme } from "core/theme";
import { DASHBOARD_HOME_URL, PARTNERS_DASHBOARD_URL } from "core/urls";

import Center from "./Center";
import Jumbotron from "./Jumbotron";
import SegmentedControl from "./SegmentedControl";
import Stack from "./Stack";

function getInitials(string) {
  // Source: https://stackoverflow.com/a/33076323
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export default function Header({ bg, mt, isPartnerPage }) {
  const history = useHistory();
  const {
    organisationId,
    setOrganisationId,
    availableOrganisations,
    organisation
  } = useOrganisationContext();

  const { userInfo } = useAppState();

  if (!userInfo || !userInfo?.user) return <Jumbotron title="Dashboard" mt={mt} />;

  if (
    !userInfo.user.partnerUserProfile?.id ||
    userInfo.user.partnerUserProfile?.tier === AFFILIATE
  ) {
    return <Jumbotron title="Dashboard" mt={mt} />;
  }

  const pb = [40, 40, 80];

  const cmpView = isPartnerPage && organisation;

  return (
    <Stack
      bg={bg}
      px={[20]}
      mt={mt}
      data-component-name="DashboardSwitcher"
      gap={0}
      pt={theme.spacing.pageHeader.pt}
      minHeight={theme.spacing.pageHeader.minHeight}
    >
      <Stack
        justifyContent={!cmpView ? "center" : null}
        maxWidth={1538}
        width="100%"
        mx="auto"
        gap={[70, 70, 0]}
        minHeight={[
          `calc(${theme.spacing.pageHeader.minHeight[0]}px - ${
            theme.spacing.pageHeader.pt[0]
          }px - ${cmpView ? pb[0] : 0}px)`,
          `calc(${theme.spacing.pageHeader.minHeight[1]}px - ${
            theme.spacing.pageHeader.pt[1]
          }px - ${cmpView ? pb[1] : 0}px)`,
          `calc(${theme.spacing.pageHeader.minHeight[2]}px - ${
            theme.spacing.pageHeader.pt[2]
          }px - ${cmpView ? pb[2] : 0}px)`
        ]}
      >
        {cmpView && (
          <OrganisationBadge
            fullName={organisation.name}
            initials={getInitials(organisation.name)}
            ml="auto"
            mt={20}
          />
        )}
        <Center mt={cmpView ? "auto" : 0}>
          <SegmentedControl
            data={[
              {
                label: "omnos",
                value: DASHBOARD_HOME_URL
              },
              {
                label: "regenerus",
                value: PARTNERS_DASHBOARD_URL
              }
            ]}
            onChange={val => {
              if (val === DASHBOARD_HOME_URL) {
                setOrganisationId(null);
              } else {
                if (!organisationId && availableOrganisations?.length) {
                  setOrganisationId(availableOrganisations?.[0]?.id);
                }
              }
              return history.push(val);
            }}
            value={isPartnerPage ? PARTNERS_DASHBOARD_URL : DASHBOARD_HOME_URL}
            height={48}
          />
        </Center>
      </Stack>
    </Stack>
  );
}

Header.defaultProps = {
  bg: "purple"
};
