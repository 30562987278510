import "@egjs/react-flicking/dist/flicking.css";
import { useHistory } from "react-router-dom";

import SliderTabs, { SliderTab } from "components/SliderTabs";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import "core/constants";
import { getPartnerDashboardTabUrl } from "core/urls";

import { CMP_PERMISSION_PLACE_ORDERS, CMP_PERMISSION_VIEW_RESULTS } from "./constants";

const TABS = [
  {
    label: "profile",
    slug: "profile",
    permissions: []
  },
  {
    label: "organisation",
    slug: "business",
    permissions: []
  },
  {
    label: "patients",
    slug: "patients",
    permissions: [CMP_PERMISSION_VIEW_RESULTS]
  },
  {
    label: "orders",
    slug: "orders",
    permissions: [CMP_PERMISSION_PLACE_ORDERS]
  },
  {
    label: "commissions",
    slug: "commissions",
    permissions: [CMP_PERMISSION_PLACE_ORDERS]
  },
  {
    label: "shop",
    slug: "shop",
    permissions: [CMP_PERMISSION_PLACE_ORDERS]
  }
];

export default function OrganisationTabs({ selectedTab }) {
  const { permissions } = useOrganisationContext();
  const history = useHistory();

  return (
    <SliderTabs
      bg="white"
      tabs={TABS}
      selectedTab={selectedTab}
      renderTab={({ tab, ...props }) => (
        <SliderTab
          {...props}
          data-component-name={`OrganisationTab:${tab.slug}`}
          tab={tab}
          disabled={
            !tab.permissions ||
            (tab.permissions.length > 0 && !tab.permissions.some(p => permissions.includes(p)))
          }
          onClick={() => {
            history.push(getPartnerDashboardTabUrl(tab.slug));
          }}
        />
      )}
    />
  );
}
