import { forwardRef } from "react";

import Box from "components/Box";

const Input = forwardRef(
  (
    {
      bg,
      placeholder,
      height,
      rightIcon,
      px,
      py,
      borderRadius,
      onBlur,
      onFocus,
      value,
      onChange,
      wrapperProps,
      name,
      type,
      ...rest
    },
    ref
  ) => {
    let pr = px;
    if (rightIcon) {
      pr = (Array.isArray(px) ? px : [px]).map(v => `calc(${v * 2}px)`);
    }

    return (
      <Box position="relative" {...wrapperProps}>
        <Box
          as="input"
          height={height}
          pl={px}
          pr={pr}
          py={py}
          ref={ref}
          borderRadius={borderRadius}
          bg={bg}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          name={name}
          type={type}
          {...rest}
        />
        {rightIcon && (
          <Box
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={pr || null}
            height={height}
          >
            {rightIcon}
          </Box>
        )}
      </Box>
    );
  }
);

Input.defaultProps = {
  type: "text"
};

export default Input;
