import React from "react";

import { LOGIN_URL } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import { CustomerAccountPage } from "./CustomerPage";
import { InternalTextLink } from "./Links";

function ResetPasswordCompletePage() {
  useDocTitle("Reset password complete");
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Password reset complete"
      }}
    >
      Your password has been successfully reset. Please click{" "}
      <InternalTextLink href={LOGIN_URL}>here</InternalTextLink> to login.
    </CustomerAccountPage>
  );
}

export default ResetPasswordCompletePage;
