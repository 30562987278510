import { useContext, useMemo } from "react";

import {
  useLocation,
  useHistory,
  useParams,
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import SliderTabs, { SliderTab } from "components/SliderTabs";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import ParamsContext from "contexts/ParamsContext";
import { REVIEW_STATUS_APPROVED } from "core/constants";
import { theme } from "core/theme";
import { PATIENT_TESTS } from "graphql/organisations/queries/patients";
import useFirstAvailableOrganisation from "hooks/organisations/useFirstAvailableOrganisation";
import { useDidUpdate } from "hooks/useDidUpdate";
import Danger from "images/Danger";

import DataLoader from "../../components/DataLoader";
import Page from "../../components/Page";
import SupplementModalContent from "../../components/SupplementModelContent";
import {
  CLIENT_MANAGEMENT_PLATFORM_URL_BASE,
  CLIENT_MANAGEMENT_USER_SUBSECTOR,
  ERROR_404_URL,
  getClientManagementTabUrl,
  getClientManagementUserSubsectorUrl,
  getClientManagementUserTestUrl,
  getPartnerShopTabUrl,
  getTPOSubsectorUrl
} from "../../core/urls";
import {
  CORE_USER_THEME_WITH_SECTORS_FIELDS,
  USER_SUBSECTOR_FIELDS
} from "../../graphql/tpo/common/types";
import Accordion from "../Accordion";
import { SymptomsList } from "../Accordions";
import { PanelBoxV2 } from "../Boxes";
import Center from "../Center";
import { CollapseableText } from "../CollapseableText";
import { ExpiredMarkersToggleSwitch } from "../ExpiredMarkersToggleSwitch";
import { FoodList } from "../FoodsPage";
import Group from "../Group";
import Menu, { Button as MenuButton } from "../Menu";
import Modal from "../Modal";
import NothingToShow from "../NothingToShow";
import Params from "../Params";
import RecommendationPage from "../RecommendationPage";
import {
  USER_SUBSECTORS_QUERY,
  PriorityRecommendations,
  RecommendationModalContent
} from "../Recommendations";
import Spacer from "../Spacer";
import Stack from "../Stack";
import TestStatuses from "../TestStatuses";
import UserDatapointPage from "../UserDatapoint";
import UserResultPage from "../UserResult";
import { CardListContext } from "../UserResults";
import {
  USER_SUBSECTOR_QUERY,
  UserSubsectorBody,
  UserSubsectorMenuItem,
  useCardList,
  useUserSubsectorsMenu
} from "../UserSubsector";
import { GET_DISPLAY_GROUPS, GenesTestContent, NonGenesTestContent } from "../UserTest";
import { UserSubsectorScores } from "../UserTheme";
import { ViewerContext } from "../Viewer";

const ALL_TABS = [
  {
    label: "tests",
    slug: "tests"
  },
  {
    label: "wellness scores",
    slug: "wellness"
  },
  {
    label: "self assessment",
    slug: "self_assessment"
  },
  {
    label: "recommendations",
    slug: "recommendations"
  },
  {
    label: "food list",
    slug: "food_list"
  }
];

const PATIENT_DETAILS = gql`
  query PatientDetails($userId: ID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
    }
  }
`;

function GenesTest({ userTest }) {
  const { patientId } = useParams();

  return (
    <DataLoader
      query={GET_DISPLAY_GROUPS}
      variables={{
        userId: patientId,
        userTestId: userTest.id
      }}
      render={({ userTest: { resultDisplayGroups } }) => (
        <GenesTestContent
          clientId={patientId}
          userTest={userTest}
          resultDisplayGroups={resultDisplayGroups}
        />
      )}
    />
  );
}

function Tests({ userTests, userTest }) {
  const { patientId } = useParams();
  const history = useHistory();

  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: theme.spacing.section.pt,
          px: 20,
          pb: theme.spacing.section.pb
        }}
      >
        <Stack gap={40}>
          <PanelBoxV2 maxWidth={[464, 464, 464, 500]}>
            {userTests.length ? (
              <TestStatuses
                heading="Available tests"
                ordersInProgress={[]}
                testResults={userTests}
                handleSelect={selected => {
                  return history.push(getClientManagementUserTestUrl(patientId, selected.id));
                }}
                defaultOpen={false}
              />
            ) : (
              <TestStatuses heading="No tests" />
            )}
          </PanelBoxV2>
          {!!userTest && (
            <Center>
              <Box>
                <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]} textAlign="center">
                  {userTest?.name}
                </Box>
                <Box textAlign="center">
                  <Box as="span" fontFamily="gilroyBold">
                    Sample taken:
                  </Box>
                  <Box as="span" fontFamily="gilroyMedium" fontSize={16} ml={1}>
                    {userTest?.sampleCollectedDateFormatted}
                  </Box>
                </Box>
                <Box textAlign="center">
                  <Box as="span" fontFamily="gilroyBold">
                    Released to client:
                  </Box>
                  <Box as="span" fontFamily="gilroyMedium" fontSize={16} ml={1}>
                    {userTest?.reviewStatus === REVIEW_STATUS_APPROVED ? (
                      <Box as="span" color="green">
                        ✓
                      </Box>
                    ) : (
                      <Box as="span" color="red">
                        ⨯
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Center>
          )}
        </Stack>
        <Box py={[15, 15, 20]} />
        {!!userTest && (
          <ViewerContext.Provider
            value={{
              userId: patientId
            }}
          >
            {userTest.isGenesTest ? (
              <GenesTest userTest={userTest} />
            ) : (
              <NonGenesTestContent
                userTest={userTest}
                showFullExplanation={false}
                clientId={patientId}
              />
            )}
          </ViewerContext.Provider>
        )}
      </PanelBoxV2>
    </>
  );
}

function NoTests() {
  return (
    <NothingToShow
      jumbotron={
        <>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            No purchased tests
          </Box>
          <Box py={[2, 2, 20]} />
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            Your patient currently has no purchased tests. Details will be available here once an
            order has been placed.
          </Box>
        </>
      }
    />
  );
}

function TestsTab() {
  const { patientId, id } = useParams();

  return (
    <DataLoader
      query={PATIENT_TESTS}
      variables={{
        userId: patientId,
        userTestId: id,
        allTests: true
      }}
      render={({ userTests, userTest }) =>
        userTest ? (
          <Tests userTests={userTests} userTest={userTest} />
        ) : (
          <Redirect to={ERROR_404_URL} />
        )
      }
    />
  );
}

const USER_THEME_QUERY = gql`
  query UserThemeQuery($themeSlug: String!, $userId: ID!) {
    userTheme(themeSlug: $themeSlug, userId: $userId) {
      ...CoreUserThemeWithSectorsFields
    }
    userSubsectors(themeSlug: $themeSlug, userId: $userId) {
      ...UserSubsectorFields
      userResultCount
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
    }
  }
  ${CORE_USER_THEME_WITH_SECTORS_FIELDS}
  ${USER_SUBSECTOR_FIELDS}
`;

function UserSubsectorMenu({ userSubsector: pageUserSubsector }) {
  const { userSubsectors, open, setOpen } = useUserSubsectorsMenu({
    themeSlug: "health"
  });
  const history = useHistory();
  const viewerCtx = useContext(ViewerContext);

  return (
    <Menu opened={open} onChange={setOpen} trigger="hover">
      <Menu.Target>
        <MenuButton
          opened={open}
          lineHeight="24px"
          paddingY="16px"
          paddingX="40px"
          maxWidth="100%"
          color="dark"
          sx={{
            color: "white"
          }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%"
            }}
          >
            {pageUserSubsector.name}
          </span>
        </MenuButton>
      </Menu.Target>
      {!!userSubsectors.length && (
        <Menu.Dropdown
          mt={2}
          py={2}
          zIndex={2}
          minWidth={310}
          maxWidth={310}
          height={500}
          maxHeight={500}
          overflowY="auto"
          bg="dark"
        >
          {userSubsectors.map(userSubsector => (
            <Menu.Item
              onClick={() =>
                history.push(
                  viewerCtx?.userId
                    ? getClientManagementUserSubsectorUrl(viewerCtx.userId, userSubsector.slug)
                    : getTPOSubsectorUrl("health", userSubsector.slug)
                )
              }
              key={userSubsector.id}
              selected={userSubsector.id === pageUserSubsector.id}
              py={16}
              selectedColor="rgba(255, 255, 255, 0.10)"
            >
              <UserSubsectorMenuItem userSubsector={userSubsector} color="white" />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}

function UserSubsector() {
  const { subsectorSlug } = useParams();
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={USER_SUBSECTOR_QUERY}
      variables={{ themeSlug: "health", subsectorSlug, userId: viewerContext?.userId }}
      render={({ userSubsector }) => (
        <UserSubsectorBody
          aboveMainChart={
            <>
              <Center>
                <UserSubsectorMenu userSubsector={userSubsector} />
              </Center>
              <Box py={[2, 2, 20]} />
            </>
          }
          userSubsector={userSubsector}
        />
      )}
    />
  );
}

function WellnessScoresTab() {
  const viewerContext = useContext(ViewerContext);

  return (
    <Switch>
      <Route path={CLIENT_MANAGEMENT_PLATFORM_URL_BASE} exact>
        <DataLoader
          query={USER_THEME_QUERY}
          variables={{
            userId: viewerContext?.userId,
            themeSlug: "health"
          }}
          render={({ userTheme, userSubsectors }) =>
            userSubsectors.length ? (
              <>
                <PanelBoxV2
                  maxWidth={760}
                  outer={{
                    pt: theme.spacing.section.pt,
                    px: 20,
                    pb: theme.spacing.section.pb,
                    bg: "white"
                  }}
                >
                  <PanelBoxV2
                    maxWidth={760}
                    outer={{
                      px: 20
                    }}
                  >
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                      Patient Health Scores
                    </Box>
                    <Box py={[2, 2, 20]} />
                    <CollapseableText fontSize={[14, 14, 16]}>
                      {`In this sector, we are looking at how various structures of the body, such as,
                      cells, tissues, organs, and systems are interlinked.

                      A healthy body is maintained by good nutrition, optimal detoxification, regular exercise, balanced hormones, avoiding harmful habits, making informed and responsible decisions about health, and seeking medical assistance when necessary.`}
                    </CollapseableText>
                  </PanelBoxV2>
                </PanelBoxV2>
                <UserSubsectorScores userTheme={userTheme} userSubsectors={userSubsectors} />
              </>
            ) : (
              <NothingToShow
                jumbotron={
                  <>
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                      No Results available
                    </Box>
                    <Box py={[2, 2, 20]} />
                    <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                      Your patients wellness results will become available once they have taken a
                      functional test or completed a symptom questionnaire.
                    </Box>
                  </>
                }
              />
            )
          }
        />
      </Route>
      <Route path={CLIENT_MANAGEMENT_USER_SUBSECTOR} component={UserSubsector} />
    </Switch>
  );
}

const GET_PARTNER_QUESTIONNAIRE_RESULTS = gql`
  query GET_PARTNER_QUESTIONNAIRE_RESULTS($userId: ID!) {
    userSubmission(userId: $userId) {
      id
      report {
        hasAnswers
        sections {
          presentationSection
          userQuestions {
            id
            question {
              id
              text
            }
            answerScore
            answerText
          }
        }
        outOfRange {
          id
          question {
            id
            text
          }
          answerScore
          answerText
        }
      }
    }
  }
`;

function SelfAssessment({ sections, outOfRange }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: 20
        }}
      >
        <PanelBoxV2
          maxWidth={760}
          outer={{
            px: 20
          }}
        >
          <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Self assessment
          </Box>
          <Box py={[2, 2, 20]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            Find below all responses to the symptoms questionnaire which form part of the wellness
            score. Please encourage your patient for a regular update every 3-6 months to keep it up
            to date.
          </CollapseableText>
          {outOfRange.length && (
            <>
              <Spacer py={[2, 2, 20]} />
              <Center>
                <Group gap={10} alignItems="center">
                  <Danger size={24} />
                  <Box color="error" fontSize={16} fontFamily="gilroyBold">
                    Includes expired answers
                  </Box>
                </Group>
              </Center>
            </>
          )}
        </PanelBoxV2>
        <Box py={[2, 2, 20]} />
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          gap={[10, 10, 20]}
          size={20}
        >
          {outOfRange.length && (
            <Accordion.Item value="outOfRange">
              <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
                <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
                  Summary - Out of range
                </Box>
              </Accordion.Control>
              <Accordion.Panel px={[20, 20, 40]}>
                <SymptomsList questions={outOfRange} />
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {sections?.map(section => (
            <Accordion.Item value={section.presentationSection} key={section.presentationSection}>
              <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
                <Box
                  fontFamily="gilroyBold"
                  fontSize={[16, 16, 18]}
                  lineHeight="130%"
                >{`Section ${section.presentationSection}`}</Box>
              </Accordion.Control>
              <Accordion.Panel px={[20, 20, 40]}>
                <SymptomsList questions={section.userQuestions} />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </PanelBoxV2>
    </>
  );
}

function SelfAssessmentTab() {
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={GET_PARTNER_QUESTIONNAIRE_RESULTS}
      variables={{
        userId: viewerContext.userId
      }}
      render={({ userSubmission }) =>
        userSubmission?.report?.hasAnswers ? (
          <SelfAssessment
            sections={userSubmission.report.sections}
            outOfRange={userSubmission.report.outOfRange}
          />
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  Self assessment
                </Box>
                <Box py={[2, 2, 20]} />
                <CollapseableText fontSize={[14, 14, 16]}>
                  The following answers from the symptom questionnaire are used in th wellness
                  scoring system. Encourage your patient to update this questionnaire every 3-6
                  months.
                </CollapseableText>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  No responses available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

function RecommendationsTab() {
  const viewerContext = useContext(ViewerContext);

  const { viewCard, closeCard, cardOpen } = useCardList();
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  const cardListApi = useMemo(
    () => ({
      viewCard,
      closeCard
    }),
    [viewCard, closeCard]
  );

  const history = useHistory();

  return (
    <DataLoader
      query={USER_SUBSECTORS_QUERY}
      variables={{
        excludeEmptyRecommendations: true,
        includeThingsToDoCount: true,
        includeThingsToAvoidCount: true,
        includeThingsToTakeCount: true,
        includePeopleToSeeCount: true,
        userId: viewerContext?.userId,
        suppressed: false,
        fresh: !includeExpiredMarkers
      }}
      render={({ userSubsectors, userTestRecommendations }) =>
        userSubsectors?.length ? (
          <>
            <CardListContext.Provider value={cardListApi}>
              <PriorityRecommendations
                header="Patient Prioritised Recommendation List"
                userSubsectors={userSubsectors}
                bodyCopy=""
              />
            </CardListContext.Provider>
            <Modal
              mode={["fullScreen", "fullScreen", "centered"]}
              bg={cardOpen?.bg}
              close={closeCard}
              show={!!cardOpen}
              maxWidth={1020}
              closeButton
              headerProps={{
                p: [20, 20, 40]
              }}
              data-component-name={
                cardOpen?.type === "recommendations" ? "RecommendationModal" : "SupplementModal"
              }
            >
              {cardOpen?.type === "recommendations" && cardOpen?.item?.id && (
                <RecommendationModalContent recommendationId={cardOpen.item.id} />
              )}
              {cardOpen?.type === "supplements" && cardOpen?.item?.id && (
                <SupplementModalContent
                  slug={cardOpen.item.slug}
                  onViewInShop={() => {
                    history.push(
                      `${getPartnerShopTabUrl("supplements")}?slug=${cardOpen.item.slug}`
                    );
                  }}
                />
              )}
            </Modal>
          </>
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  {PriorityRecommendations.defaultProps.header}
                </Box>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  No recommendations available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

const GET_FIRST_USER_TEST = gql`
  query GetFirstUserTest($userId: ID!) {
    userTests(userId: $userId) {
      id
    }
  }
`;

function Template({ children, selectedTab }) {
  const { patientId } = useParams();
  const history = useHistory();

  const { data } = useQuery(PATIENT_DETAILS, {
    variables: {
      userId: patientId
    }
  });

  const user = data?.user;

  return (
    <CustomerPage
      bg="haze"
      jumbotronProps={{
        bg: "partners",
        color: "dark",
        title: user ? `${user.firstName} ${user.lastName}` : null,
        top: <ExpiredMarkersToggleSwitch />
      }}
    >
      {data ? (
        user ? (
          <ViewerContext.Provider
            value={{
              userId: patientId
            }}
          >
            <SliderTabs
              bg="white"
              tabs={ALL_TABS}
              selectedTab={selectedTab}
              withBorder
              renderTab={({ tab, ...props }) => (
                <SliderTab
                  {...props}
                  tab={tab}
                  onClick={() => {
                    history.push(getClientManagementTabUrl(patientId, tab.slug));
                  }}
                />
              )}
            />
            {children}
          </ViewerContext.Provider>
        ) : (
          <Redirect to={ERROR_404_URL} />
        )
      ) : null}
    </CustomerPage>
  );
}

function ClientManagementPlatform() {
  const match = useRouteMatch();
  const { patientId } = useParams();

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={getClientManagementTabUrl(patientId, "tests")} />
      </Route>
      <Route path={`${match.path}/tests`} exact>
        <DataLoader
          query={GET_FIRST_USER_TEST}
          variables={{
            userId: patientId
          }}
          render={({ userTests }) =>
            userTests.length ? (
              <Redirect to={getClientManagementUserTestUrl(patientId, userTests[0].id)} />
            ) : (
              <Template selectedTab="tests">
                <NoTests />
              </Template>
            )
          }
        />
      </Route>
      <Route path={`${match.path}/tests/:id`} exact>
        <Template selectedTab="tests">
          <TestsTab />
        </Template>
      </Route>
      <Route path={`${match.path}/wellness`}>
        <Template selectedTab="wellness">
          <WellnessScoresTab />
        </Template>
      </Route>
      <Route path={`${match.path}/self_assessment`} exact>
        <Template selectedTab="self_assessment">
          <SelfAssessmentTab />
        </Template>
      </Route>
      <Route path={`${match.path}/recommendations`} exact>
        <Template selectedTab="recommendations">
          <RecommendationsTab />
        </Template>
      </Route>
      <Route path={`${match.path}/food_list`} exact>
        <Template selectedTab="food_list">
          <FoodList />
        </Template>
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}

const IS_CONNECTED_TO_PATIENT = gql`
  query IsConnectedToPatient($patientId: ID!) {
    isConnectedToPatient(patientId: $patientId)
  }
`;

export default function ClientManagementPlatformRouting() {
  const match = useRouteMatch();
  const location = useLocation();
  const { patientId } = useParams();
  const { data, refetch } = useQuery(IS_CONNECTED_TO_PATIENT, {
    variables: {
      patientId
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  useDidUpdate(() => {
    refetch();
  }, [location.pathname, refetch]);

  const { loadingElement } = useFirstAvailableOrganisation();

  if (loadingElement) return loadingElement;
  if (data && !data?.isConnectedToPatient) {
    return <Redirect to={ERROR_404_URL} />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/result/:userResultId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <UserResultPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/biomarker/:userDatapointId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <UserDatapointPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/recommendations/:recommendationId`}>
        {/* Not sure it's possible through the UI to get here but this is in case any old links
        exist pointing to the old /partner ... urls.  For these we'd redirect to here */}
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <RecommendationPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}`} component={ClientManagementPlatform} />
    </Switch>
  );
}
