import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import Image from "components/Image";
import { theme } from "core/theme";
import Icon from "images/tpo/self_assesment.svg";
import ButtonV2 from "v2/Buttons";

import { getQuestionnaireStepUrl } from "../core/urls";
import ChevronComponent from "./Chevron";
import { useIntro } from "./QuestionnaireIntro";
import { LoadingPage } from "./QuestionnairePages";
import Spacer from "./Spacer";
import Stack from "./Stack";

function CompleteQuestionnaireIntro() {
  const { handleClick, submission } = useIntro(getQuestionnaireStepUrl("symptoms", 1));

  if (!submission) {
    return <LoadingPage />;
  }

  return (
    <CustomerPage bg="blue">
      <Stack
        mt={94}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        minHeight="100%"
        gap={[20, 20, 40]}
        alignItems="center"
        px={20}
      >
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} color="white">
          Questionnaire
        </Box>
        <Stack
          bg="white"
          px={[20, 20, 130]}
          pt={[40, 40, 60]}
          pb={[60, 60, 80]}
          gap={40}
          maxWidth={1020}
          width="100%"
          borderRadius={5}
        >
          <Stack gap={20}>
            <Image
              src={Icon}
              width={115}
              height={115}
              style={{
                alignSelf: "center"
              }}
            />
            <Box
              fontFamily="gilroyBold"
              fontSize={[18, 18, 24]}
              textAlign="center"
              style={{
                textTransform: "uppercase"
              }}
              as="h2"
            >
              symptom questionnaire
            </Box>
          </Stack>
          <Box>
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
              Welcome
            </Box>
            <Spacer py={2} />
            <Box
              as="ol"
              fontSize={[14, 14, 16]}
              lineHeight="150%"
              style={{
                listStylePosition: "inside"
              }}
            >
              Our Free Symptoms Questionnaire is your essential companion on the path to improved
              health and well-being. Unlock a wealth of benefits:
              <Spacer py={2} />
              <li>
                <Box as="span" fontFamily="gilroyBold">
                  Holistic Assessment:
                </Box>{" "}
                Our questionnaire provides a comprehensive overview of your current health status,
                acting as a crucial starting point for your wellness journey.
              </li>
              <li>
                <Box as="span" fontFamily="gilroyBold">
                  Personalised Insights:
                </Box>{" "}
                Upon completion, you'll receive a detailed wellness score. This score not only
                identifies areas where you can enhance your well-being but also offers tailored
                recommendations prioritised to your unique needs.
              </li>
              <li>
                <Box as="span" fontFamily="gilroyBold">
                  Targeted Test Recommendations:
                </Box>{" "}
                We'll also evaluate and align our available tests with your specific symptoms. This
                means you'll gain precise guidance on the next steps to gain deeper insights into
                your body's current functioning.
              </li>
              <Spacer py={2} />
              Embark on your wellness journey with confidence, armed with valuable insights and a
              clear roadmap towards a healthier you.
              <Spacer py={2} />
              (Approximately 5 minutes to complete)
            </Box>
          </Box>
          <ButtonV2
            color="dark"
            onClick={handleClick}
            mx="auto"
            rightIcon={<ChevronComponent fill="white" />}
            size={["sm", "sm", "md"]}
          >
            {submission.complete ? "Edit" : "Get Started"}
          </ButtonV2>
        </Stack>
      </Stack>
    </CustomerPage>
  );
}

export default CompleteQuestionnaireIntro;
