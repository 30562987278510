import { useCallback } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Loading from "components/Loading";
import OrganisationTabs from "components/organisations/OrganisationTabs";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { OUT_OF_STOCK } from "core/constants";
import { theme } from "core/theme";
import { ERROR_404_URL } from "core/urls";
import {
  PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
  SHOP_LISTING_FILTERS_QUERY
} from "graphql/shop";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";
import TestProductListCard from "tpo/partnerDashboard/practitioner/TestProductListCard";
import ListPageTemplate from "tpo/shop/ListPageTemplate";
import {
  SupplementControls,
  SupplementDetailModal,
  SupplementList,
  SupplementsProvider
} from "tpo/shop/SupplementListPage";
import useTestProducts from "tpo/shop/hooks/useTestProducts";

function TestProductListContent({ sampleTypes, categories, testingServices }) {
  const history = useHistory();
  const location = useLocation();

  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Recommended",
        value: "sort_order"
      },
      {
        label: "A - Z",
        value: "name"
      },
      {
        label: "Z - A",
        value: "-name"
      },
      {
        label: "Price Asc",
        value: "trade_current_price_annotation"
      },
      {
        label: "Price Desc",
        value: "-trade_current_price_annotation"
      }
    ]
  });

  const {
    productFilters: { menu, selectedFilters },
    products: { items, hasMore, endCursor },
    loading,
    fetchMore
  } = useTestProducts({
    query: PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
    connectionsFieldName: "practitionerTestProductConnections",
    testingServices,
    categories,
    sampleTypes,
    search: debouncedSearch,
    sort: controls.sort,
    productFiltersDrawerBg: "dark"
  });

  const { selectTestProductId } = useTestProductModalContext();
  const {
    addProductToBasket,
    basket,
    hasBasketGotProduct,
    removeProductFromBasket
  } = useOrganisationBasketContext();
  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();

  const handleAddToBasket = useCallback(
    testProduct => {
      if (testProduct.options?.length || (!basket.purchaseAsStock && testProduct.addons?.length)) {
        selectTestProductId(testProduct.id);
        return;
      }
      addProductToBasket(testProduct.id);
      selectTestProductId(null);
      setBasketOpen(true);
    },
    [addProductToBasket, selectTestProductId, setBasketOpen, basket]
  );

  const handleRemoveFromBasket = useCallback(
    testProduct => {
      const line = basket.productLineItems.find(line => line.product.id === testProduct.id);
      // clinic location should not be present within the context this function is called
      // but add anyway to be explicit
      removeProductFromBasket(line.product.id, line.productOption?.id, line.clinicLocation?.id);
    },
    [removeProductFromBasket, basket]
  );

  return (
    <ListPageTemplate
      // TODO - use the standard url approach involving core.urls
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests"
      }}
      bg="haze"
      tab="tests"
      title="Find a matching test"
      subtitle={`Search or filter our comprehensive list of functional tests to find the most appropriate test for your patient. `}
      pb={[30, 30, 60]}
    >
      <PanelBoxV2
        maxWidth={1220}
        outer={{
          bg: "haze",
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        <Stack gap={20}>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            gap={20}
            justifyContent={[null, null, "space-between"]}
            flexWrap="wrap"
          >
            <Group gap={10}>{menu}</Group>
            <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
              <Search
                value={search}
                onChange={s => {
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("search", s);
                  history.push({
                    path: location.path,
                    search: searchParams.toString()
                  });
                }}
                maxWidth={[null, null, 350]}
                minWidth={310}
                width="100%"
              />
              <Box display="flex" justifyContent="flex-end">
                <SortMenu
                  value={controls.sort}
                  setValue={controls.setSort}
                  open={controls.sortMenuOpen}
                  setOpen={controls.setSortMenuOpen}
                  options={controls.sortOptions}
                  placeholder="Sort By"
                />
              </Box>
            </Box>
          </Box>
          {selectedFilters}
        </Stack>
        <Spacer py={[2, 2, 20]} />
        <InfiniteScroll
          loader={<Loading />}
          hasMore={hasMore}
          loading={loading}
          next={() => {
            if (endCursor) {
              fetchMore({
                variables: {
                  after: endCursor,
                  first: 10
                }
              });
            }
          }}
          items={items}
        >
          <Stack>
            <InfiniteScrollContext.Consumer>
              {({ itemsList, setBottomElement }) => (
                <>
                  {itemsList.map((testProduct, idx) => {
                    let addText = "Add";
                    if (
                      testProduct.options?.length ||
                      (!basket?.purchaseAsStock && testProduct.addons?.length)
                    ) {
                      addText = "Select option";
                    } else if (!basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id)) {
                      addText = "Added";
                    } else if (testProduct.status === OUT_OF_STOCK) {
                      addText = "Out of stock";
                    }
                    return (
                      <TestProductListCard
                        productCode={testProduct.id}
                        hasAddons={testProduct.addons?.length > 0}
                        addDisabled={
                          testProduct.status === OUT_OF_STOCK ||
                          (!basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id))
                        }
                        title={testProduct.name}
                        subtitle={
                          <Box color="anchorBlue" fontFamily="gilroyMedium" fontSize={14}>
                            {testProduct.laboratory}
                          </Box>
                        }
                        addText={addText}
                        pills={
                          <>
                            {testProduct.content.testType && (
                              <Badge bg="purple" color="white" size="xs">
                                {testProduct.content.testType}
                              </Badge>
                            )}
                            {testProduct.content.categories
                              .map(cat => cat.name)
                              .map(c => (
                                <Badge bg="haze" color="dark" size="xs" key={c}>
                                  {c}
                                </Badge>
                              ))}
                          </>
                        }
                        tradeCurrentPrice={testProduct.tradeCurrentPrice}
                        tradeFullPrice={testProduct.tradeFullPrice}
                        retailFullPrice={testProduct.retailFullPriceForPractitionerShop}
                        key={testProduct.id}
                        onAdd={
                          basket?.purchaseAsStock ||
                          (!basket?.purchaseAsStock && !hasBasketGotProduct(testProduct.id))
                            ? e => {
                                e.onAdd = true;
                                if (
                                  basket?.purchaseAsStock ||
                                  !hasBasketGotProduct(testProduct.id)
                                ) {
                                  handleAddToBasket(testProduct);
                                }
                              }
                            : undefined
                        }
                        onRemove={
                          !basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id)
                            ? e => {
                                e.onRemove = true;
                                handleRemoveFromBasket(testProduct);
                              }
                            : undefined
                        }
                        onSelect={e => {
                          if (e.onAdd || e.onRemove) return;
                          selectTestProductId(testProduct.id);
                        }}
                        ref={idx === itemsList.length - 1 ? setBottomElement : null}
                      />
                    );
                  })}
                </>
              )}
            </InfiniteScrollContext.Consumer>
          </Stack>
        </InfiniteScroll>
      </PanelBoxV2>
    </ListPageTemplate>
  );
}

function TestProductListPage() {
  return (
    <>
      <OrganisationTabs selectedTab="shop" />
      <DataLoader
        query={SHOP_LISTING_FILTERS_QUERY}
        variables={{
          includeFilters: ["Both", "B2B"]
        }}
        render={({ sampleTypes, categories, testingServices, userTestRecommendations }) => {
          return (
            <TestProductListContent
              sampleTypes={sampleTypes}
              categories={categories.map(cat => cat.name)}
              testingServices={testingServices}
              userTestRecommendations={userTestRecommendations}
            />
          );
        }}
      />
    </>
  );
}

function SupplementListPage() {
  const { organisation } = useOrganisationContext();
  const { basket, addSupplementToBasket } = useOrganisationBasketContext();
  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();

  const { data, error, loading } = useQuery(SHOP_LISTING_FILTERS_QUERY);

  const onAddToBasket = useCallback(
    ({ supplement }) => {
      addSupplementToBasket(supplement.id);
      setBasketOpen(true);
    },
    [addSupplementToBasket, basket]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <SupplementsProvider
      dietryRestrictions={data?.dietryRestrictions}
      types={data?.types}
      brands={data?.brands}
      productFiltersDrawerBg="dark"
    >
      <ListPageTemplate
        bg="haze"
        urlMap={{
          supplements: "/partners/dashboard/shop/supplements",
          tests: "/partners/dashboard/shop/tests"
        }}
        header={<OrganisationTabs selectedTab="shop" />}
        tab="supplements"
        title="Find a matching supplement"
        subtitle="Use the sample type or testing service below to find the test type that matches your sample. Alternatively choose a client to see their recommended tests."
        pb={[30, 30, 60]}
      >
        <PanelBoxV2
          maxWidth={1220}
          outer={{
            bg: "haze",
            pb: theme.spacing.section.pb,
            px: [20, 20, "5.5vw"]
          }}
        >
          <SupplementControls />
          <SupplementList onAddToBasket={onAddToBasket} />
        </PanelBoxV2>
        <SupplementDetailModal onAddToBasket={onAddToBasket} />
      </ListPageTemplate>
    </SupplementsProvider>
  );
}

export default function OrganisationShop() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={`${match.path}/tests`} />
      </Route>
      <Route path={`${match.path}/supplements`} exact>
        <SupplementListPage />
      </Route>
      <Route path={`${match.path}/tests`} exact>
        <TestProductListPage />
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
