import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import { gql } from "@apollo/client";
import AuthRoute from "components/AuthRoute";
import CustomerPage from "components/CustomerPage";
import CustomerTabs from "components/CustomerTabs";
import DataLoader from "components/DataLoader";
import { ERROR_404_URL, getTestResultsUrl } from "core/urls";

import { ExpiredMarkersToggleSwitch } from "./ExpiredMarkersToggleSwitch";
import UserTest from "./UserTest";

const GET_FIRST_USER_TEST = gql`
  query GetFirstUserTest {
    userTests {
      id
    }
  }
`;

export default function UserTests() {
  const match = useRouteMatch();
  return (
    <Switch>
      <AuthRoute exact path={match.path}>
        {/* In the menu for example we have a 'Test Results' link.  This route takes
        care of working out the first test id */}
        <CustomerPage
          bg="haze"
          includeFooter
          jumbotronProps={{
            title: "Test Results",
            top: <ExpiredMarkersToggleSwitch />
          }}
        >
          <CustomerTabs selectedTab="/test-results" />
          <DataLoader
            query={GET_FIRST_USER_TEST}
            render={({ userTests }) => (
              <Redirect
                to={userTests?.length ? getTestResultsUrl(userTests?.[0]?.id) : ERROR_404_URL}
              />
            )}
          />
        </CustomerPage>
      </AuthRoute>
      <AuthRoute path={`${match.path}/:userTestId`} component={UserTest} />
    </Switch>
  );
}
